import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.config.globalProperties.$isDev = process.env.NODE_ENV === "development";
app.config.globalProperties.$isDev = false;

app.use(router);
app.mount("#app");
